import {parseGid} from '@shopify/admin-graphql-api-utilities';
import {Redirect} from '@shopify/app-bridge/actions';
import {useAppBridge} from '@shopify/app-bridge-react';
import {
  Badge,
  type BadgeProps,
  BlockStack,
  Button,
  LegacyStack,
  type LegacyStackProps,
  Link,
  SkeletonBodyText,
  SkeletonThumbnail,
  Text,
  type ThumbnailProps,
} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import React from 'react';

import {ThumbnailWithFallback} from '~/components';
import {useEncodedAppPath} from '~/hooks';
import {emptyI18nConfig} from '~/utils/createI18nConfig';

import styles from './ProductInfo.module.css';
import {type ProductInfoProduct} from './types';

interface BaseProductInfoProps {
  children?: React.ReactNode;
  hidePriceRange?: boolean;
  subdued?: boolean;
  thumbnailSize?: ThumbnailProps['size'] | 'extraLarge' | 'extraExtraLarge';
  badge?: string;
  badgeTone?: BadgeProps['tone'] | 'magic';
  verticalAlignment?: LegacyStackProps['alignment'];
  layout?: 'horizontal' | 'vertical';
  monochromeLink?: boolean;
}

export interface ProductInfoProps extends BaseProductInfoProps {
  product: ProductInfoProduct;
  isLoading?: boolean;
}

interface ProductInfoLoadingProps extends BaseProductInfoProps {
  isLoading: true;
  product?: ProductInfoProduct;
}

/**
 * Handles showing the product info with thumbnails, prices and links to the product page.
 */
export function ProductInfo({
  children,
  product,
  badge,
  badgeTone = 'info',
  thumbnailSize = 'small',
  hidePriceRange = false,
  isLoading = false,
  subdued = false,
  verticalAlignment = 'leading',
  layout = 'horizontal',
  monochromeLink = false,
}: ProductInfoProps | ProductInfoLoadingProps) {
  const [i18n] = useI18n(emptyI18nConfig);
  const app = useAppBridge();
  const encodedAppPath = useEncodedAppPath({includeQueryParams: true});
  const wrapperClasses = (() => {
    switch (thumbnailSize) {
      case 'extraLarge':
        return styles.extraLargeThumbnail;
      case 'extraExtraLarge':
        return styles.extraExtraLargeThumbnail;
      default:
        return '';
    }
  })();
  const finalThumbnailSize =
    thumbnailSize === 'extraLarge' || thumbnailSize === 'extraExtraLarge'
      ? 'large'
      : thumbnailSize;

  if (isLoading) {
    if (layout === 'horizontal') {
      return (
        <LegacyStack wrap={false} alignment={verticalAlignment}>
          <div className={wrapperClasses}>
            <SkeletonThumbnail size={finalThumbnailSize} />
          </div>
          <LegacyStack.Item fill>
            <SkeletonBodyText lines={2} />
          </LegacyStack.Item>
        </LegacyStack>
      );
    }
    return (
      <BlockStack gap="400">
        <div className={wrapperClasses}>
          <SkeletonThumbnail size={finalThumbnailSize} />
        </div>
        <SkeletonBodyText lines={2} />
      </BlockStack>
    );
  }

  if (!product) {
    return null;
  }

  const priceMarkup = (() => {
    if (hidePriceRange) {
      return null;
    }

    const priceArr = [product.minPrice];

    if (product.maxPrice !== product.minPrice) {
      priceArr.push(product.maxPrice);
    }

    return priceArr
      .filter((price) => !isNaN(price))
      .map((val) => i18n.formatCurrency(val))
      .join(' - ');
  })();

  const productTitleMarkup = (() => {
    const onClick = () => {
      Redirect.create(app).dispatch(Redirect.Action.ADMIN_PATH, {
        path: `/products/${parseGid(product.id)}?return_to=${encodedAppPath}`,
      });
    };
    return monochromeLink ? (
      <Link monochrome removeUnderline dataPrimaryLink onClick={onClick}>
        {product.title}
      </Link>
    ) : (
      <div className={styles.productTitle}>
        <Button
          role="link"
          disabled={subdued}
          onClick={onClick}
          variant="plain"
        >
          {product.title}
        </Button>
      </div>
    );
  })();

  return (
    <LegacyStack
      wrap={false}
      alignment={verticalAlignment}
      vertical={layout === 'vertical'}
      spacing="baseTight"
    >
      <div className={wrapperClasses}>
        <ThumbnailWithFallback
          source={product.image?.url}
          alt={product.image?.altText}
          size={finalThumbnailSize}
        />
      </div>
      <LegacyStack.Item fill>
        <BlockStack gap="050">
          {productTitleMarkup}
          <Text
            variant="bodyMd"
            tone={subdued ? 'subdued' : undefined}
            as="span"
          >
            {priceMarkup}
          </Text>
          {children}
        </BlockStack>
      </LegacyStack.Item>
      {badge ? <Badge tone={badgeTone}>{badge}</Badge> : null}
    </LegacyStack>
  );
}
