import {type ApolloClient, useMutation} from '@apollo/client';
import {useEffect, useState} from 'react';

import {
  MetafieldOwnerType,
  MetaobjectUserErrorCode,
  StandardMetafieldDefinitionsEnableUserErrorCode,
} from '~/types/graphql/core-types';
import {MetaObjectDefinitions, MetafieldDefinitions} from '~/utils/constants';

import {StandardMetaDefinitionsEnableDocument} from '../graphql/StandardMetaDefinitionsEnableMutation.core.graphql.generated';

export function useEnableMetaDefinitions({
  client,
}: {
  client: ApolloClient<any>;
}) {
  const [
    {enabledDefinitions, enablingDefinitions},
    setEnablingDefinitionsState,
  ] = useState({enablingDefinitions: false, enabledDefinitions: false});
  const [userError, setUserError] = useState<Error>();
  const [enableStandards] = useMutation(StandardMetaDefinitionsEnableDocument, {
    client,
  });

  useEffect(() => {
    if (enablingDefinitions || enabledDefinitions) {
      return;
    }
    (async function enableMetafields() {
      try {
        setEnablingDefinitionsState({
          enablingDefinitions: true,
          enabledDefinitions: false,
        });

        const metafieldDefinitionsToEnable = [
          MetafieldDefinitions.ProductBoosts,
          MetafieldDefinitions.ProductRecommendations,
        ].flatMap(({namespace, metafields}) => {
          return Object.values(metafields).map(({key}) => ({
            ownerType: MetafieldOwnerType.Product,
            namespace,
            key,
          }));
        });

        const {data, errors} = await enableStandards({
          variables: {
            metafieldDefinitions: metafieldDefinitionsToEnable,
            metaobjectType: MetaObjectDefinitions.SynonymGroup.type,
          },
        });

        const unexpectedGQLErrors = errors ?? [];

        // These are GQL layer errors, not user errors. Therefor we throw only the first one to have complete information inside bugsnag reports.
        if (unexpectedGQLErrors.length > 0) {
          throw unexpectedGQLErrors[0];
        }

        const unexpectedMetafieldErrors = (
          data?.standardMetafieldDefinitionsEnable?.userErrors || []
        ).filter(
          ({code}) =>
            code !== StandardMetafieldDefinitionsEnableUserErrorCode.Taken,
        );

        const unexpectedMetaobjectErrors = (
          data?.standardMetaobjectDefinitionEnable?.userErrors || []
        ).filter(
          ({code}) =>
            code !== MetaobjectUserErrorCode.ObjectFieldTaken &&
            code !== MetaobjectUserErrorCode.Taken,
        );

        const unexpectedUserErrors = [
          ...unexpectedMetafieldErrors,
          ...unexpectedMetaobjectErrors,
        ];

        if (unexpectedUserErrors.length > 0) {
          throw new Error(
            unexpectedUserErrors
              .map((userError) => userError.message)
              .join('&'),
          );
        }

        setEnablingDefinitionsState({
          enablingDefinitions: false,
          enabledDefinitions: true,
        });
      } catch (error: any) {
        setUserError(error);
        setEnablingDefinitionsState({
          enablingDefinitions: false,
          enabledDefinitions: false,
        });
      }
    })();
  }, [enableStandards, enabledDefinitions, enablingDefinitions]);

  return {enablingDefinitions, error: userError};
}
