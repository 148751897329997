import * as Types from '../../../types/graphql/core-types';

import type {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
import {gql} from '@apollo/client';
export type StandardMetaDefinitionsEnableMutationVariables = Types.Exact<{
  metafieldDefinitions:
    | Array<Types.StandardMetafieldDefinitionsEnableInput>
    | Types.StandardMetafieldDefinitionsEnableInput;
  metaobjectType: Types.Scalars['String']['input'];
}>;

export type StandardMetaDefinitionsEnableMutation = {
  __typename: 'Mutation';
  standardMetafieldDefinitionsEnable: {
    __typename: 'StandardMetafieldDefinitionsEnablePayload';
    userErrors: Array<{
      __typename: 'StandardMetafieldDefinitionsEnableUserError';
      code: Types.StandardMetafieldDefinitionsEnableUserErrorCode | null;
      field: Array<string> | null;
      message: string;
    }>;
  } | null;
  standardMetaobjectDefinitionEnable: {
    __typename: 'StandardMetaobjectDefinitionEnablePayload';
    userErrors: Array<{
      __typename: 'MetaobjectUserError';
      field: Array<string> | null;
      code: Types.MetaobjectUserErrorCode | null;
      message: string;
    }>;
  } | null;
};

export const StandardMetaDefinitionsEnableDocument = gql`
  mutation StandardMetaDefinitionsEnable(
    $metafieldDefinitions: [StandardMetafieldDefinitionsEnableInput!]!
    $metaobjectType: String!
  ) {
    standardMetafieldDefinitionsEnable(definitions: $metafieldDefinitions) {
      userErrors {
        code
        field
        message
      }
    }
    standardMetaobjectDefinitionEnable(type: $metaobjectType) {
      userErrors {
        field
        code
        message
      }
    }
  }
` as unknown as DocumentNode<
  StandardMetaDefinitionsEnableMutation,
  StandardMetaDefinitionsEnableMutationVariables
>;
